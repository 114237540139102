.therapy {
	.therapy__logo {
		object-fit: contain;
		max-height: 50vh;
	}
	.insta-button:hover {
		color: rgb(88, 88, 88);
	}
}

@media (min-width: 576px) {
	.therapy .notice-board {
		width: 75%;
	}
}
@media (min-width: 992px) {
	.individual-therapy {
		border-right: solid rgb(201, 200, 200) 1px;
	}
}
