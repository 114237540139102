.testimonials .carousel-item {
	max-height: none !important;
}
.carousel-inner {
	border-radius: 2px;
	background-color: #b5cfe8;
}
.testimonials .carousel-caption {
	top: 50%;
	transform: translateY(-50%);
	bottom: initial;
	color: #00102e;
}

@media (max-width: 768px) {
	.testimonials .carousel-caption {
		font-size: 1.2rem;
	}
	.testimonials .carousel-item {
		min-height: 70vh;
	}
}
@media (max-width: 576px) {
	.carousel-inner {
		overflow: scroll;
	}
	.carousel-caption p {
		font-size: 1rem !important;
	}
}
.carousel-background {
	min-height: 400px;
}
.carousel-caption,
.carousel-caption p {
	font-size: 1rem;
}
