.cta__image {
	max-height: 70vh;
	object-fit: cover;
	object-position: right bottom;
	padding: 0rem;
}
.cta {
	background: url("../../public/assets/images/therapist/1.jpg");
	background-color: black;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: bottom center;
	min-height: 90vh;
}
.cta div {
	padding-top: 5rem;
}
@media (max-width: 768px) {
	.cta div {
		padding-top: 2rem;
	}
	.dropdown-menu {
		font-size: 1rem;
	}
}
@media (max-width: 475px) {
	.dropdown-menu {
		font-size: 0.8rem;
	}
}

.about img {
	width: 100%;
	object-fit: cover;
	/* max-height: 70vh; */
}
#dropdown-basic {
	background-color: white;
	border: none;
	font-size: 1rem;
}
#dropdown-basic:hover,
#dropdown-basic:focus {
	border: none;
	box-shadow: none;
}
#education-qualifications {
	font-size: 1rem;
}
