.contact {
	.contact__header {
		background: url("../../public/assets/images/xx.jpeg");
		background-color: black;
		/* background-repeat: no-repeat; */
		/* background-size: cover; */
		background-position: center;
		min-height: 90vh;
	}
	.contact-link {
		font-size: 1rem;
	}
}

@media (max-width: 400px) {
	.contact-link {
		overflow: scroll;
	}
}
.contact-img {
	max-height: 80vh;
}
