.footer__logo {
	height: 150px;
	border-radius: 5px;
}
.footer a {
	color: black;
	text-decoration: none;
}
.footer a:hover {
	color: rgba(0, 0, 0, 0.459);
}
.footer {
	background-color: #ededed37;
}
.footer-text {
	font-size: 0.8rem;
}
