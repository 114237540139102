.newsletter {
	min-height: 75vh;
}
#newsletter-img {
	/* width:100%; */
	height: 90vh;
	object-fit: cover;
}
@media (max-width: 475px) {
	#newsletter-img {
		max-height: 80vh;
	}
}
